const NODE_ENV = process.env.NODE_ENV;

let urls = {
BASE_URL: "",
API_URL: "",
SERVER_URL: "",
DOC_URL: "",
};

if (NODE_ENV === "development") {
urls = {
    API_URL: "http://127.0.0.1:8005/api", 
    SERVER_URL: "http://127.0.0.1:8005",
    // API_URL: "https://test.gdfplay.io/api",
    // MEDIA_URL: "https://test.gdfplay.io/media",
    AUTH_URL: "https://test-auth.gdfenhancer.com",
    BASE_URL: "http://127.0.0.1:5173",

    /* test */
    // AUTH_URL: "http://localhost:8081",
    // BASE_URL: "https://test.gdfplay.io",
    DOC_URL: "https://doc.gdflab.com",
    GUIDE_URL: "https://guide.gdfplay.io",
};
} else if (NODE_ENV === "staging") {
urls = {
    BASE_URL: "https://test.gdfplay.io",
    API_URL: "https://test.gdfplay.io/api",
    MEDIA_URL: "https://test.gdfplay.io/media",
    AUTH_URL: "https://test-auth.gdfenhancer.com",
    DOC_URL: "https://doc.gdflab.com",
    GUIDE_URL: "https://guide.gdfplay.io",
};
} else {
urls = {
    BASE_URL: "https://gdfplay.io",
    API_URL: "https://gdfplay.io/api",
    MEDIA_URL: "https://gdfplay.io/media",
    AUTH_URL: "https://auth.gdflab.com",
    DOC_URL: "https://doc.gdflab.com",
    GUIDE_URL: "https://guide.gdfplay.io",
};
}

export default urls;
