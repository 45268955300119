import { createApp } from "vue";
import VueEasyLightbox from "vue-easy-lightbox";
import router from "./routes";
import "bootstrap";
import "./index.css";
import App from "./App.vue";

let app = createApp(App);
app.use(router);
app.use(VueEasyLightbox);

import VueLogger from "vuejs3-logger";
console.log(process.env.NODE_ENV);
const isProduction = process.env.NODE_ENV === "production";

const options = {
  isEnabled: true,
  logLevel: isProduction ? "error" : "debug",
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: "|",
  showConsoleColors: true,
};

app.use(VueLogger, options);

import {createI18n} from 'vue-i18n'

import msg from '@/assets/messages/index.js'
import store from './store'
const messages = Object.assign(msg)


store.commit('loadLang')
var saved_lang = store.getters.readLang
if (saved_lang === undefined | !saved_lang) {
    saved_lang = navigator.language =='ko'? "한국어" : "English"
    store.commit('saveLang', saved_lang)
}


var i18n = new createI18n({
  locale: saved_lang,
  allowComposition: true,
  fallbackLocale: 'English',
  messages,
  warnHtmlInMessage: 'off' 
})

app.use(i18n)

app.use(store)

import authentication from "@/plugins/authentication";
import gdflabApi from "@/api/api";

app.use(authentication);

const auth = app.config.globalProperties.$auth;

auth
  .init({ onLoad: "check-sso", checkLoginIframe: true })
  // .catch(() => {location.reload})
  .then((authenticated) => {
    // this.$log.info(auth)

    if (authenticated) {
      gdflabApi.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${auth.token}`;
      // app.mount("#app");
      setInterval(() => {
        auth
          .updateToken(70)
          .then((refreshed) => {
            if (refreshed) {
              if (!isProduction) {
                console.info("Token refreshed" + refreshed);
              }
            } else {
              if (!isProduction) {
                console.debug(
                  "Token not refreshed, valid for " +
                    Math.round(
                      auth.tokenParsed.exp +
                        auth.timeSkew -
                        new Date().getTime() / 1000
                    ) +
                    " seconds"
                );
              }
            }
          })
          .catch(() => {
            console.error("Failed to refresh token");
            location.reload;
          });
      }, 6000);
    } else if (gdflabApi.defaults.headers.common["Authorization"]) {
      delete gdflabApi.defaults.headers.common["Authorization"];
    }
    app.mount("#app");
  });

if (process.env.NODE_ENV === "development") {
  app.mount("#app");
}
// app.mount("#app");
export default app