import Keycloak from "keycloak-js";
import URL from "@/api/urls";
import logger from "@/libs/logging";

const NODE_ENV = process.env.NODE_ENV
const url = URL.AUTH_URL

/* test */
// let realmId = "gdflab";
// let clientId = "gdfplay";

let realmId = "staging";
let clientId = "gdfplay-dev";

if (NODE_ENV == "production") {
  realmId = "production";
  clientId = "gdfplay";
} else if (NODE_ENV == "staging") {
  realmId = "staging";
  clientId = "gdfplay";
} else {
  logger.log.debug("realm", `is ${realmId}`);
}

const onLoad = "check-sso";

const initOptions = {
    url: url,
    realm: realmId,
    clientId: clientId,
    onLoad: onLoad,
    silentCheckSsoRedirectUri: window.location.origin + "/",
  };
  
  const _keycloak = new Keycloak(initOptions);
  

  const Plugin = {
    install: (app) => {
      app.config.globalProperties.$auth = _keycloak;
    },
  };
  
  
  export default Plugin;